/* global gtag, IIVT, Lightbox, objectFitPolyfill, Remus */

import './vendor/iivt.min.js';
import './vendor/ajax.min.js';
import './vendor/objectfitpolyfill.basic.min.js';
import './vendor/remus.js';
import './vendor-es6/lightbox.js';

export { LoadOMatic } from './vendor-es6/Loadomatic.js';

( () => {
	'use strict';

	setupFormValidation();

	( () => {
		// fix 100vh variable height on ios
		const els = document.querySelectorAll( '[data-calc-ios-vh]' );
		if ( !els[0] ) return;

		const user_agent = window.navigator.userAgent.toLowerCase();
		const ios = /iphone|ipod|ipad/.test( user_agent );
		if ( !ios ) return;

		const updateHeights = () => {
			const y0 = getWindowHeight();
			for ( let i = 0; i < els.length; i++ ) setiOSHeight( els[i], y0 );
		};

		updateHeights();
		window.addEventListener( 'orientationchange', updateHeights, false );

		function setiOSHeight( el, base_height ) {
			el.style.height = ( base_height * parseFloat( el.getAttribute( 'data-calc-ios-vh' ) ) ) + 'px';
		}
	} )();

	( () => {
		// Cookie notice
		if ( document.cookie.indexOf( 'seenCookieNotice' ) === -1 ) {
			document.cookie = 'seenCookieNotice=yes;path=/;max-age=31536000;secure';
			document.body.classList.add( 'show-cookie-notice' );
			document.querySelector( '.cookie-notice button' ).addEventListener( 'click', () => {
				document.body.classList.remove( 'show-cookie-notice' );
			} );
		}
	} )();

	( () => {
		const els = document.querySelectorAll( '[data-track-action]' );
		if ( !els[0] ) {
			return;
		}
		for ( let i = 0; i < els.length; i++ ) {
			els[i].addEventListener( 'click', sendEvent( els[i] ) );
		}

		function sendEvent( el ) {
			const a = el.getAttribute( 'data-track-action' );
			const l = el.getAttribute( 'data-track-label' );
			return () => {
				gtag( 'event', a, { 'event_label': l } );
			};
		}
	} )();

	( () => {
		// Object fit polyfill
		const els = document.querySelectorAll( '.page__header__image' );
		if ( !els[0] ) return;

		objectFitPolyfill( els );
	} )();

	( () => {
		const links = document.querySelectorAll( '[data-lightbox-trigger]' );
		if ( !links[0] ) {
			return;
		}

		const lightbox = new Lightbox();

		for ( let i = 0; i < links.length; i++ ) {
			hookLink( links[i] );
		}

		function hookLink( el ) {
			el.addEventListener( 'click', ( e ) => {
				e.preventDefault();
				lightbox.open();
				lightbox.updateInner( el );
			} );
		}
	} )();

	( () => {
		// Nav triggers
		const triggers = document.querySelectorAll( '[data-nav-trigger]' );

		for ( let i = 0; i < triggers.length; i++ ) {
			init( triggers[i] );
		}

		function init( el ) {
			el.addEventListener( 'click', () => {
				document.body.classList.toggle( 'nav-open' );
			}, false );
		}
	} )();

	( () => {
		const els = document.querySelectorAll( '.remus' );
		if ( !els[0] ) return;

		for ( let i = 0; i < els.length; i++ ) {
			init( els[i] );
		}

		function init( el ) {
			new Remus( {
				element: '#' + el.id,
				height: null
			} );
		}
	} )();

	( () => {
		// Search triggers
		const trigger = document.querySelector( '[data-search-trigger]' );
		const search_container = document.querySelector( '.header__search' );
		const form_input = search_container.querySelector( '.search__input' );
		const form_close = search_container.querySelector( '.search__close' );

		if ( document.body.classList.contains( 'search-open' ) ) form_input.focus();
		trigger.addEventListener( 'click', openSearch() );
		form_close.addEventListener( 'click', closeSearch );

		function closeSearch() {
			document.body.classList.remove( 'search-open' );
		}
		function openSearch() {
			return ( e ) => {
				e.preventDefault();
				document.body.classList.add( 'search-open' );
				form_input.focus();
			};
		}
	} )();

	( () => {
		// Social share popups
		const els = document.querySelectorAll( '[data-share]' );
		if ( !els[0] ) return;

		for ( let i = 0; i < els.length; i++ ) {
			els[i].addEventListener( 'click', handleSocialShare( els[i] ) );
		}

		function popUpWindow( url ) {
			const popup_width = 550;
			const popup_height = 420;
			const x = ( window.screen.width / 2 ) - ( popup_width / 2 );
			const y = ( window.screen.height / 2 ) - ( popup_height / 2 ) - 50;
			const new_window = window.open( url, '', 'dependent=1,height=' + popup_height.toString() + ',width=' + popup_width.toString() + ',left=' + x.toString() + ',top=' + y.toString() + ',resizable=0,status=0' );
			if ( window.focus ) new_window.focus();
			return false;
		}
		function handleSocialShare( btn ) {
			const url = btn.href;
			return ( e ) => {
				e.preventDefault();
				popUpWindow( url );
			};
		}
	} )();

	( () => {
		// Video embeds
		const videos = document.querySelectorAll( '[data-video-embed]' );
		if ( !videos[0] ) return;

		for ( let i = 0; i < videos.length; i++ ) {
			videos[i].addEventListener( 'click', handleVideoClick( videos[i] ) );
		}

		function handleVideoClick( video ) {
			return ( e ) => {
				e.preventDefault();
				video.querySelector( 'iframe' ).src += '?autoplay=1';
				video.classList.add( 'video-loaded' );
			};
		}

	} )();

	function setupFormValidation( container = document ) {
		// Handle ajax forms
		const forms = container.querySelectorAll( '[data-form]' );
		if ( !forms[0] ) return;

		IIVT.addValidators( {
			not_empty: ( input ) => {
				return input.value.trim() !== '';
			}, email: ( input ) => {
				const a = /.+@.+\.[a-zA-Z0-9]+/.test( input.value.trim() );
				const b = input.value.trim().indexOf( '+' );
				return ( a && ( b === -1 ) );
			}, checked: ( input ) => {
				return ( input.checked );
			}
		} );

		for ( let i = 0; i < forms.length; i++ ) {
			initForm( forms[i] );
		}

		function initForm( container ) {
			if ( container.hasAttribute( 'data-form-ready' ) ) return;

			const form = container.querySelector( '.form__main' );
			const inputs = form.querySelectorAll( '[data-validators]' );
			for ( let j = 0; j < inputs.length; j++ ) {
				inputs[j].addEventListener( 'input', handleInputChange( inputs[j], true ) );
				inputs[j].addEventListener( 'blur', handleInputChange( inputs[j], false ) );
			}

			form.addEventListener( 'submit', ( e ) => {
				e.preventDefault();
				if ( handleFormSubmit( inputs ) ) {
					if ( typeof FormData === 'undefined' || !container.hasAttribute( 'data-ajax-form' ) ) {
						return;
					}
					handleAjaxForm( container, form );
				}
			} );
			container.setAttribute( 'data-form-ready', '' );
		}

		function handleAjaxForm( container, form ) {
			let xml = new XMLHttpRequest();
			const formdata = new FormData( form );
			xml.open( form.method, form.action.value );
			xml.setRequestHeader( 'Accept', 'application/json' );
			xml.setRequestHeader( 'X-Requested-With', 'XMLHttpRequest' );
			xml.send( formdata );
			xml.addEventListener( 'readystatechange', () => {
				if ( xml.readyState === 4 ) {
					const msg = container.querySelector( '.form__feedback' );
					if ( httpSuccess( xml ) ) {
						container.classList.add( 'form--success' );
					} else {
						msg.innerHTML = 'Sorry, there was a problem. Please try again later.';
					}
					xml = null;
				}
			} );
		}
		function httpSuccess( r ) {
			try {
				return !r.status && location.protocol === 'file:' || ( r.status >= 200 && r.status < 300 ) || r.status === 304 || navigator.userAgent.indexOf( 'Safari' ) >= 0 && typeof r.status === 'undefined';
			} catch ( e ) {
				//
			}
			return false;
		}
		function handleFormSubmit( inputs ) {
			let form_is_valid = true;
			for ( let i = 0; i < inputs.length; i++ ) {
				const next_valid = validateInput( inputs[i], false );
				form_is_valid = form_is_valid && next_valid;
			}
			if ( form_is_valid ) {
				window.location.hash = '';
				return true;
			}
		}
		function handleInputChange( input, quiet_invalidate ) {
			return () => {
				window.setTimeout( () => {
					validateInput( input, quiet_invalidate );
				}, 1 );
			};
		}
		function validateInput( input, quiet_invalidate ) {
			const valid = IIVT.isValid( input ).valid;
			let row = input.parentNode;
			if ( input.hasAttribute( 'data-validators-inner' ) ) {
				row = row.parentNode;
			}
			valid ? row.classList.remove( 'form__row--invalid' ) : ( !quiet_invalidate ? row.classList.add( 'form__row--invalid' ) : '' );
			return valid;
		}
	}

	// Helpers
	function getWindowHeight() {
		return ( window.innerHeight || document.documentElement.clientHeight );
	}

} )();
